<template>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4" style="text-align: center;">
      <div v-if="booking" style="font-weight: bold;font-size: 22px;">
        {{ booking.booking_date }} | {{ booking.formatted_time }} <br>
        {{ booking.route.code }}
      </div>
      <hr>
      <div>CURRENT STATUS:</div>
      <h5> {{ getCurrentStatus() }}</h5>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
    <div class="col-md-4" style="text-align: center;margin: 10px;">
      <div class="row">
        
        <div class="col-md-12" v-if="booking.status != 2">
          <hr>
          <div class="form-group">
            <label>Departure Time</label>
            <div>Please provide the actual departure time for delayed trips</div>
            <el-time-select v-model="departure_time" :picker-options="{
                  start: '03:00',
                  step: '00:05',
                  end: '21:00'
                }" placeholder="Select time">
            </el-time-select>
          </div>
    
            <confirm-button title="Are you sure you want to depart this trip?"
              label="DEPART" :size="'large'"
              :type="'success'" class="mt-3" @onClick="handleUpdate(2)" klass="btn-block mb-2 font-30">
            </confirm-button>
        </div>

        
        <div class="col-md-12" v-if="booking.status != 1">
          <confirm-button title="Are you sure to open this trip?"
          label="OPEN" :size="'large'"
          :type="'primary'" class="mt-3" @onClick="handleUpdate(1)" klass="btn-block mb-2 font-30">
        </confirm-button>
        </div>
        <div class="col-md-12" v-if="booking.status != 3">
          <confirm-button title="Are you sure to hold issuance for this trip?"
          label="HOLD ISSUANCE" :size="'large'"
          :type="'info'" class="mt-3" @onClick="handleUpdate(3)" klass="btn-block mb-2 font-30">
        </confirm-button>
        </div>

        <div class="col-md-12" v-if="booking.status != 0">
          <confirm-button title="Are you sure to cancel this trip?"
          label="CANCEL" :size="'large'"
          :type="'danger'" class="mt-3" @onClick="handleUpdate(0)" klass="btn-block mb-2 font-30">
        </confirm-button>
        </div>
      </div>

    </div>
  </div>
</template>
<script>

import { Booking } from '@/resources/booking'
import ConfirmButton from '../../Common/ConfirmButton.vue'


export default {
  components: { ConfirmButton },
  props: ['booking'],
  data() {
    return {
      departure_time: null
    }
  },
 
  created: function () {
    if (this.booking) this.departure_time = this.booking.time
  },
  methods: {
    getCurrentStatus() {
      if (!this.booking) return "N/A"
      if (this.booking.status == 0) return 'CANCELLED'
      if (this.booking.status == 1) return 'OPEN'
      if (this.booking.status == 2) return 'DEPARTED'
         if (this.booking.status == 3) return 'ON HOLD'
    },
    handleUpdate(status) {
      this.$loading = true
      Booking.updateStatus(this.booking.id, {status: status, departure_time: this.departure_time })
        .then(result => {
          this.$emit('save', true)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>
<style lang="scss">
.font-30 {
  font-size: 30px;
}
</style>
