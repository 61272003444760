<template>
  <div class="row">

    <el-drawer title="" :visible.sync="showFullScreen" direction="btt" :before-close="handleClose" size="100%"
      :destroy-on-close="true" :append-to-body="true" :show-close=false>
      <div style="text-align: center">
        <img src="@/assets/images/app_logo.png" style="height:65px;padding:0" />
        <hr>
      </div>
      <div style="padding: 10px" class="row">
        <div class="col-md-12">

          <h5 class="title">
            <back label="CHECK-IN PASSENGERS"></back>

          </h5>
        </div>
        <div class="col-md-7 mb-2">

          <el-card v-if="booking" style="margin-bottom: 10px;">
            <div>

            </div>
            <div style="text-align: center;">
              <div>STATUS</div>
              <div style="font-weight: bold;font-size: 20px;">{{ $store.state.bookings_status[booking.status] }}</div>
              <div class="bold">{{ booking.route_code }} ({{ booking.vessel.name }})</div>
              <div class="bold">{{ booking.booking_date }} | {{ booking.formatted_time }}</div>
              <div class="text-primary">ON-SITE TICKETS: {{ booking.data.on_site_tickets_count }} 
                | BOARDED: {{ booking.data.boarded_on_site_tickets }}
                | UNBOARDED: {{ booking.data.unboarded_on_site_tickets }}
              </div>
              <br>

              <div class="text-primary">ONLINE TICKETS: {{ booking.data.online_tickets_count }} 
                | BOARDED: {{ booking.data.boarded_online_tickets }}
                | UNBOARDED: {{ booking.data.unboarded_online_tickets }}
              </div>
              <br>
              <div class="text-primary">TOTAL BOARDED: {{ booking.data.checked_in_tickets }}</div>
              <div class="text-danger">UNBOARDED: {{ booking.data.unboarded_on_site_tickets + booking.data.unboarded_online_tickets  }}</div>
              <hr>
              <div>
                <el-button type="text" @click="handleViewManifest" class="text-primary" style="font-size: 15px;">VIEW
                  MANIFEST</el-button>
                <el-button type="text" @click="handleVesselSeats" class="text-primary" style="font-size: 15px;">VIEW
                  SEAT PLAN</el-button>
              </div>
              <el-button type="primary" @click="getCheckinTickets('booking_only')" size="large" plain>UPDATE LIST</el-button>
              <hr>
              
              <div ><el-alert :closable="false" type="info" style="font-size: 30px">Boarding pass and passenger list printing is only available on the day of the trip.</el-alert></div>
              <div v-if="booking.is_on_the_day">
                <confirm-button title="You're about to print all online tickets. Proceed?"
                 label="PRINT ONLINE TICKETS BOARDING PASS" :size="'medium'"
                :type="'primary'" class="mb-3" @onClick="handlePrintAllOnlineTickets()" klass="btn-block">
              </confirm-button><br><br>

              <confirm-button title="You're about to print advance tickets. Proceed?"
                      label="PRINT ADVANCE TICKETS IN THERMAL" :size="'medium'"
                :type="'primary'" class="mb-3" @onClick="handlePrintThermalTicketsList('advance_only')" klass="btn-block">
              </confirm-button><br><br>

              <confirm-button title="You're about to print on the day tickets. Proceed?"
                      label="PRINT ON THE DAY TICKETS IN THERMAL" :size="'medium'"
                :type="'primary'" class="mt-3" @onClick="handlePrintThermalTicketsList('on_the_day')" klass="btn-block">
              </confirm-button> <br><br>

              <confirm-button title="You're about to print all tickets. Proceed?"
                      label="PRINT ALL TICKETS IN THERMAL" :size="'medium'"
                :type="'primary'" class="mt-3" @onClick="handlePrintThermalTicketsList('all')" klass="btn-block">
              </confirm-button>
              </div>
            </div>
            <div>
            </div>
          </el-card>
          <br>
          <div>
            <el-button @click="showScanner = true" type="info" size="large">SCAN QR CODE</el-button>
            <el-button @click="showScanner = false" type="danger" size="large" style="float: right;"
              v-if="showScanner"><i class="fa fa-close"></i></el-button>
          </div>
          <div style="background-color: #909399;" v-if="showScanner">
            <CameraCodeScanner @scan="onScan" @load="onLoad" v-if="showScanner"></CameraCodeScanner>
          </div>
          <hr>
          <div>
            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-md-6">
                  <el-input v-model="term" placeholder="Search passenger name or code" size="large"></el-input>
                </div>
                <div class="col-md-6">
                  <el-button-group>
                    <el-button type="primary" icon="el-icon-search" size="large" native-type="submit">SEARCH</el-button>
                    <el-button type="info" size="large" @click="clear"><i class="el-icon-refresh-left"></i>
                      CLEAR</el-button>
                  </el-button-group>

                  <!-- <div>
                <el-button type="primary" plain class="primary" native-type="submit" size="small">SEARCH
                  KEYWORD</el-button>
                <el-button type="button" style="background-color: #909399;color: #fff;" size="small"
                  @click="clear">CLEAR</el-button>
              </div> -->
                </div>
              </div>



              <!-- <el-input v-model="name" placeholder="Search by name" class="input-code" size="small">
            <el-button slot="append" class="primary" style="margin-right: 10px;" native-type="submit"
              size="small">SEARCH BY
              NAME</el-button>
            <el-button slot="append" type="button" style="background-color: #909399;color: #fff;" size="small"
              @click="clear">CLEAR</el-button>
          </el-input> -->
            </form>
          </div>
        </div>
        <div class="col-md-5 mb-2">
          <div v-if="!$loading && !found_tickets.length">
            <hr>
            NO PASSENGERS FOUND
          </div>
          <el-button class="full padding-20" @click="handleDownloadBoardingPass(code)" type="success" size="large"
            v-if="found_tickets.length && code"><i class="fa fa-print"></i> PRINT GROUP BOARDING PASS</el-button>
          <br>
          <el-button class="full padding-20" @click="handleManualAssignSeat(code)" type="primary" size="large"
            v-if="found_tickets.length && code"><i class="fa fa-print"></i> MANUAL-ASSIGN GROUP TICKETS</el-button><br>
          <el-button class="full padding-20" @click="handleDownloadBoardingPass(code, 'auto')" type="primary"
            size="large" v-if="found_tickets.length && code"><i class="fa fa-print"></i> AUTO-ASSIGN AND PRINT GROUP
            TICKETS</el-button>

          <div v-for="ticket in found_tickets" :key="ticket.id" style="margin-bottom: 10px;;margin-top:10px">
            <el-card :class="ticket.ticket_seat ? 'bg-light-blue' : ''">
              <div>GROUP CODE: {{ ticket.group_code }}</div>
              <div>CODE: {{ ticket.code }}</div>
              <div>FULL NAME: <span style="font-weight: bold">{{ ticket.full_name }}</span></div>
              <div>GENDER: {{ $store.state.gender_list[ticket.gender] }}</div>
              <div>ACCO: {{ ticket.accommodation.name }}</div>
              <div>SEAT NO.: <span style="font-weight: bold;font-size: 30px">{{ ticket.ticket_seat ?
                  ticket.ticket_seat.seat_number :
                  ''
                  }}</span></div>
              <div>
                <span v-if="ticket.ticket_seat && ticket.ticket_seat.is_boarded">CHECKED-IN</span>
              </div>
              <hr>
              <div>

                <el-button-group>
                  <el-button @click="handleAssignSeat(ticket, 'auto')" plain type="warning" size="large"
                    class="font-20 btn-block">AUTO ASSIGN</el-button>
                  <el-button @click="handleAssignSeat(ticket)" plain type="primary" size="large"
                    class="font-20 btn-block">{{
                    ticket.ticket_seat ?
                    'CHANGE' : 'ASSIGN' }} SEAT</el-button>
                  <el-button @click="handleDownloadBoardingPass(ticket.code)" type="success" size="large"
                    class="font-20 btn-block" v-if="ticket.ticket_seat && booking.is_on_the_day"><i class="fa fa-print"></i> PRINT</el-button>

                  <el-button @click="handleBoardTIcket(ticket)" class="font-20 btn-block"
                    :type="ticket.ticket_seat && ticket.ticket_seat.is_boarded ? 'info' : 'primary'" size="large"
                    v-if="ticket.ticket_seat && !ticket.ticket_seat.is_boarded"
                    :disabled="ticket.ticket_seat && ticket.ticket_seat.is_boarded"><i class="fa fa-ship"></i>
                    <span v-if="ticket.ticket_seat && !ticket.ticket_seat.is_boarded">CHECK-IN</span>
                  </el-button>
                  <!-- {{ ticket.ticket_seat && ticket.ticket_seat.is_boarded ? 'BOARDED' :'BOARD & PRINT' }}</el-button> -->
                </el-button-group>


              </div>
            </el-card>
          </div>
        </div>

      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showPassengers" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div v-if="booking">
        <BookingTicketsList :booking="booking"></BookingTicketsList>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showAssignSeat" direction="btt" :before-close="handleClose" size="95%"
      :destroy-on-close="true" :append-to-body="true">
      <div>
        <ticket-assign-seat :ticket="selected" :booking="booking" v-if="selected" @save="updateSeat($event)" @removeSeat="removeSeat()"
          :autoAssign="autoAssign"></ticket-assign-seat>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showVesselSeats" direction="btt" :before-close="handleClose" size="95%"
      :destroy-on-close="true">
      <div class="" v-if="booking && booking.vessel">
        <vessel-seats-list :vessel="booking.vessel" :booking="booking" :allow_block="true"></vessel-seats-list>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showManualAssign" direction="btt" :before-close="handleClose" size="70%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="">
        <div class="row">
          <div class="col-md-12">
            <group-ticket-assign-seat :group_ticket="group_ticket" v-if="group_ticket" :all="false"
              @close="getCheckinTickets('tickets_only')" :booking="booking"></group-ticket-assign-seat>
          </div>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import {
  Booking
} from '@/resources/booking'
import {
  Ticket
} from '@/resources/ticket'
import {
  LocalStorageService
} from '@/services/LocalStorageService'

import {
  CameraCodeScanner
} from "vue-barcode-qrcode-scanner";
import Back from '@/components/Common/Back.vue';
import TicketAssignSeat from '../../Ticketing/TicketAssignSeat.vue';
import VesselSeatsList from '../../Vessels/VesselSeatsList.vue';
import GroupTicketAssignSeat from '../../Ticketing/GroupTicketAssignSeat.vue';

import {
  GroupTicket
} from '@/resources/group_ticket'
import BookingTicketsList from '../BookingTicketsList.vue';
import ConfirmButton from '../../../Common/ConfirmButton.vue';


export default {
  components: {
    CameraCodeScanner,
    Back,
    TicketAssignSeat,
    VesselSeatsList,
    GroupTicketAssignSeat,
    BookingTicketsList,
    ConfirmButton
  },
  data() {
    return {
      showFullScreen: true,
      showAssignSeat: false,
      showScanner: false,
      code: null,
      term: null,
      tickets: [],
      found_tickets: [],
      booking: null,
      selected: null,
      showVesselSeats: false,
      autoAssign: false,
      showManualAssign: false,
      group_ticket: null,
      showPassengers: false
    }
  },
  created: function () {
    this.getCheckinTickets('booking_only')
  },
  methods: {
    handlePrintThermalTicketsList(option){
      this.$loading = true
      this.$loadingText = "Printing tickets list on thermal"

      Ticket.printThermalTicketsList({booking_id: this.booking.id, option: option})
        .then(result => {
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
          this.handleClose()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handlePrintAllOnlineTickets(){
      this.$loading = true
      this.$loadingText = "Printing boarding pass to all online tickets"

      Ticket.printBoardingPassToAllOnlineTickets({booking_id: this.booking.id})
        .then(result => {
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
          this.handleClose()
          this.getCheckinTickets('tickets_only')
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleViewManifest() {
      this.showPassengers = true
    },
    handleManualAssignSeat() {
      this.$loading = true
      GroupTicket.show(this.code)
        .then(result => {
          this.group_ticket = result.data.group_ticket
          this.showManualAssign = true
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })

    },
    handleVesselSeats() {
      this.showVesselSeats = true
    },
    handleAssignSeat(model, isAuto) {
      this.showAssignSeat = true
      this.selected = Object.assign({}, model)
      this.autoAssign = isAuto
      // if (print) this.selected.print = true
    },
    handleClose() {
      this.showAssignSeat = false
      this.showVesselSeats = false
      this.autoAssign = false
      this.showManualAssign = false
      this.showPassengers = false
    },
    updateSeat(val) {
      this.selected.ticket_seat = val
      let self = this
      this.tickets.forEach(function (ticket) {
        if (self.selected.id == ticket.id) {
          ticket.ticket_seat = val
        }
      })
      this.showAssignSeat = false
      // this.handleDownloadBoardingPass(this.selected.code)
    },
    removeSeat() {

      this.selected.ticket_seat = null
      let self = this
      this.tickets.forEach(function (ticket) {
        if (self.selected.id == ticket.id) {
          ticket.ticket_seat = null
        }
      })
      this.showAssignSeat = false
      // this.handleDownloadBoardingPass(this.selected.code)
    },
    clear() {
      this.code = null
      this.term = null
      this.found_tickets = []
    },
    onLoad({
      controls,
      scannerElement,
      browserMultiFormatReader
    }) {

    },
    onScan({
      result,
      raw
    }) {
      this.term = result
      this.showScanner = false
      this.searchFromTickets('tickets_only')
    },
    handleSubmit() {
      this.searchFromTickets('tickets_only')
    },
    searchFromTickets() {
      this.getCheckinTickets('tickets_only')
    },
    searchFromList() {
      this.found_tickets = []
      let foundTickets = []
      let self = this
      let group_codes = []

      if (!this.term) return
      let term = this.term.toUpperCase()

      this.$loading = true

      this.tickets.forEach(function (ticket) {
        let full_name = ticket.full_name ? ticket.full_name.toUpperCase() : ''
        if (ticket.code == term || ticket.group_code == term || ticket.online_booking_code == term) {
          group_codes.push(ticket.group_code)
          foundTickets.push(ticket)
        } else if (full_name.indexOf(term) !== -1) {
          foundTickets.push(ticket)
        }
      })

      group_codes = [...new Set(group_codes)]
      if (group_codes.length == 1) this.code = group_codes[0]
      this.found_tickets = foundTickets
      this.$loading = false
      if (!this.found_tickets.length) {
        this.$notify({
            type: 'error',
            title: "Error",
            text: 'Passenger Not Found',
          });
      }
    },
    getCheckinTickets(option = null) {
      //options
      // null = fetch booking and tickets
      // booking_only = fetch booking only
      // tickets_only = fetch tickets only

      this.$loading = true
      this.handleClose()
      console.log(this.term)
      LocalStorageService.setCheckinTickets(this.tickets)
      let params = {
          booking_id: this.$route.params.id,
          option: option,
          term:this.term
        }

      Ticket.checkinTickets(params)
        .then(result => {
          if (!option) {
            this.booking = result.data.booking
            this.tickets = result.data.tickets
            LocalStorageService.setCheckinTickets(this.tickets)
          }else if (option == 'booking_only') {
            this.booking = result.data.booking
          }
          else if (option == 'tickets_only') {
            this.tickets = result.data.tickets
            LocalStorageService.setCheckinTickets(this.tickets)
            this.searchFromList()
          }         
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleBoardTIcket(ticket) {
      this.$loading = true
      this.$loadingText = "Boarding passenger"

      Ticket.board(ticket.id)
        .then(result => {
          ticket.ticket_seat.is_boarded = true
          // this.handleDownloadBoardingPass(ticket.code)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadBoardingPass(code, auto) {
      this.$loading = true
      this.$loadingText = "Preparing to generate boarding pass"

      Ticket.downloadGroupTicketBoardingPass({
        code: code,
        auto: auto
        })
        .then(result => {
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
          this.handleClose()
          this.getCheckinTickets('tickets_only')
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })

    },
  }
}
</script>

<style lang="scss">
.input-code .el-input-group__append .primary {
  background-color: #409EFF;
  color: #ffffff;
}
</style>
